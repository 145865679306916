import Container from '@/components/atoms/Container'
import Icon from '@/components/atoms/Icon'
import {Course} from '@/components/types/course'
import {lg} from '@/config'
import {extractDatesForCalendar} from '@/functions/formatCalendar'
import useMediaQuery from '@/functions/useMediaQuery'
import classNames from 'classnames'
import moment from 'moment'
import 'moment/locale/de'
import {useRouter} from 'next/router'
import {useCallback, useState} from 'react'
import {
  Calendar as CalendarComponent,
  momentLocalizer
} from 'react-big-calendar'
import {useTranslation} from 'react-i18next'
import styles from './Calendar.module.scss'

type CalendarProps = {
  block: {
    courses: Course[]
  }
}

export default function Calendar({block}: CalendarProps) {
  const {courses} = block

  const getCurrentDate = () => {
    const now = new Date()
    return new Date(now.getFullYear(), now.getMonth(), 1)
  }

  const [currentDate, setCurrentDate] = useState(() => {
    return getCurrentDate()
  })
  const {locale, push} = useRouter()
  const {t} = useTranslation()
  const handleSelectEvent = useCallback((event: any) => push(event.uri), [])

  let events = extractDatesForCalendar(courses)
  const localizer = momentLocalizer(moment)
  const isLg = useMediaQuery(lg)

  const getDaysInMonth = (date: Date) => {
    return moment(date).daysInMonth()
  }

  const handleMonthChange = (direction: 'prev' | 'next') => {
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + (direction === 'next' ? 1 : -1),
      1
    )

    const now = new Date()
    const minDate = new Date(now.getFullYear(), now.getMonth(), 1)
    if (newDate < minDate) {
      return
    }

    setCurrentDate(newDate)
  }

  const currentMonthYear = () => {
    moment.locale(locale)
    return moment(currentDate).format('MMMM YYYY')
  }

  const CustomAgendaEvent = ({
    event
  }: {
    event: {title: string; stockQuantity: number}
  }) => (
    <span
      className={classNames(
        styles.title,
        event.stockQuantity === null ? styles.isSoldOut : ''
      )}
    >
      {event.title}
    </span>
  )

  const CustomAgendaDate = ({day}: {day: Date}) => {
    moment.locale(locale)
    const formattedDate = moment(day).format('dd, DD.MM.YY')
    return <span>{formattedDate}</span>
  }

  const CustomEventContent = ({event}: {event: any}) => {
    return (
      <div
        className={classNames(
          styles.event,
          event.stockQuantity === null ? styles.isSoldOut : ''
        )}
      >
        {event.title}
      </div>
    )
  }

  const formatOverlayHeader = (date: Date) =>
    moment(date).format('D. MMMM YYYY')

  return (
    <Container>
      <div className={styles.container}>
        <div className={styles.toolbar}>
          {isLg && (
            <div className={styles.currentMonth}>{currentMonthYear()}</div>
          )}
          <div className={styles.toolbarWrapper}>
            <button
              className={classNames(
                styles.leftArrow,
                getCurrentDate().getTime() === currentDate.getTime()
                  ? styles.isDisabled
                  : ''
              )}
              onClick={() => handleMonthChange('prev')}
            >
              <Icon icon={'arrowRightOutline'} size={40} sizeViewbox={40} />
            </button>
            <span
              onClick={() => setCurrentDate(getCurrentDate())}
              className={styles.textToolbar}
            >
              {isLg ? t('global:todayLabel') : currentMonthYear()}
            </span>
            <button
              className={styles.rightArrow}
              onClick={() => handleMonthChange('next')}
            >
              <Icon icon={'arrowRightOutline'} size={40} sizeViewbox={40} />
            </button>
          </div>
        </div>
        {/* @ts-ignore */}
        <CalendarComponent
          culture={locale}
          formats={{
            weekdayFormat: (date) => moment(date).format('dd')
          }}
          localizer={localizer}
          events={events}
          onSelectEvent={handleSelectEvent}
          startAccessor="start"
          endAccessor="end"
          view={isLg ? 'month' : 'agenda'}
          date={currentDate}
          length={getDaysInMonth(currentDate) - 1}
          messages={{
            date: t('global:dateLabel'),
            noEventsInRange: t('global:noEvents'),
            showMore: (count: number) => `+${count} ${t('global:moreLabel')}`
          }}
          onShowMore={(events, date) => {
            setTimeout(() => {
              const overlayHeader = document.querySelector(
                '.rbc-overlay-header'
              )
              if (overlayHeader) {
                overlayHeader.textContent = formatOverlayHeader(date)
              }
            }, 0)
          }}
          components={{
            agenda: {
              /* @ts-ignore */
              date: CustomAgendaDate,
              event: CustomAgendaEvent
            },
            event: CustomEventContent
          }}
          popup
        />
      </div>
    </Container>
  )
}
